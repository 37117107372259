@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --default-cell-border-color: theme('colors.slate.700');
  --default-cell-text-color: theme('colors.white');
  --absent-cell-bg-color: theme('colors.slate.700');
  --correct-cell-bg-color: theme('colors.green.400');
  --present-cell-bg-color: theme('colors.yellow.400');
}

.high-contrast {
  --correct-cell-bg-color: theme('colors.orange.400');
  --present-cell-bg-color: theme('colors.cyan.400');
}

html {
  background-color: #41658a;
  overflow: hidden;
}

.cell-cursor-animation > .letter-container {
  animation: blink-animation 1s step-start 0s infinite;
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

svg.cursor-pointer {
  transition: all var(--animation-speed-fast);
}

svg.cursor-pointer:hover {
  transform: scale(1.2);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-content {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
}

.title {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: -1;
}

.left-icons {
  display: flex;
}

.right-icons {
  display: flex;
}

.game {
  position: absolute;
  top: 3.5rem;
  bottom: 9.5rem;
  left: 0;
  right: 0;
}

.modals {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 3.5rem;
}

.keyboard {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
}

.finish-coffee {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
}

@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: transparent;
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
    border-width: 4px;
  }
  50% {
    background-color: transparent;
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
    border-width: 4px;
  }
  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: transparent;
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
    border-width: 4px;
  }
  50% {
    background-color: transparent;
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
    border-width: 4px;
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: transparent;
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
    border-width: 4px;
  }
  50% {
    background-color: transparent;
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
    border-width: 4px;
  }
  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}

.buymeacoffee {
  display: block;
}

.buymeacoffee img {
  display: inline-block;
  height: 36px;
  border: 0;
}

@media (max-height: 660px) {
  .game {
    transition: all 1s;
    transform: scale(0.9);
    transform-origin: top;
  }
}

@media (max-height: 610px) {
  .game {
    transition: all 1s;
    transform: scale(0.8);
    transform-origin: top;
  }
}

@media (max-height: 570px) {
  .game {
    transition: all 1s;
    transform: scale(0.7);
    transform-origin: top;
  }
}

@media (max-height: 540px) {
  .game {
    transition: all 1s;
    transform: scale(0.6);
    transform-origin: top;
  }
}

.firework {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-color: transparent;
}

.redirect {
  position: absolute;
  background-color: #41658a;
  text-align: center;
  z-index: 1000;
}

.redirect img {
  display: inline;
}

*:focus {
  outline: none;
}

.carousel.carousel-slider {
  padding-bottom: 40px;
}

.carousel .thumbs-wrapper.axis-vertical {
  display: none;
}

@media (max-width: 768px) {
  .modal {
    position: fixed;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
  }
}
